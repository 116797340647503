/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, Switch, Redirect, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import {
  AUTH_BACKGROUND,
  LOGO,
  PRODUCT_NAME,
  PRODUCT_YEAR,
  WELCOME_HEADER,
  WELCOME_TEXT,
} from "../../../../Config/config";

import backimg from "../Login Image/SmartLogin.png";
import ResetPassword from "./ResetPassword";
import { Mobile } from "../../../../hooks/mobile";

export function AuthPage(props) {
  const history = useHistory();
  const imageUrl = "../Login Image/SmartLogin.png";
  const isSmallScreen = Mobile();

  const divStyle = {
    backgroundImage: `url(${backimg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    // height: '400px',
  };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
          // style={{
          //   backgroundImage: `url(${toAbsoluteUrl("/media/bg/authbg.png")})`,
          // }}
          style={divStyle}
        >
          {/*begin::Aside*/}
          <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10">
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between pb-lg-15">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img alt="Logo" className="w-50" src={toAbsoluteUrl(LOGO)} />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content font-size-h1 */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center ml-lg-15 ">
                <h3
                  className="mb-5 "
                  style={{
                    fontSize: "36px",
                    fontWeight: "600",
                    textAlign: "center",
                    letterSpacing: "0.2px",
                    color: "#070550",
                  }}
                >
                  {/* {WELCOME_HEADER} */}
                  WELCOME TO <br /> SMART MINING
                </h3>
                <div style={{ textAlign: isSmallScreen ? "start" : "center" }}>
                  <p className="font-weight-lighter text-black opacity-80">
                    {WELCOME_TEXT}
                  </p>
                </div>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-black">
                  &copy; {PRODUCT_YEAR} {PRODUCT_NAME}
                </div>
                {/* <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link>
                </div> */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center  mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/register" component={Registration} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password"
                  component={ResetPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {PRODUCT_YEAR} {PRODUCT_NAME}
              </div>
              {/* <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div> */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
