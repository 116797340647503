/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useHistory, useLocation, Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { shallowEqual, useSelector } from "react-redux";
import { MdFileCopy } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import { useRef } from "react";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Avatar, ConfigProvider, Divider, Popover, Select } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import { BiUserCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import * as actions from "../../../../app/modules/ECommerce/_redux/categoriesAction/collectionsActions";
import { EmailOutlined } from "@material-ui/icons";
import { BsFile } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
// import { io } from "socket.io-client";
import {
  deleteNotificationByUserId,
  editNotificationById,
  getNotificationByFilter,
  getNotificationByUserId,
} from "../../../../services/ApiServices";
import { Badge, IconButton, Typography } from "@material-ui/core";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);

    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  const { addToast } = useToasts();
  const myRef = useRef();
  const [isCopied, setIsCopied] = useState(false);

  const functtocopy = () => {
    // navigator.clipboard.writeText(user.wallets[0])
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    return addToast("Address Copied", {
      appearance: "success",
      autoDismiss: "true",
    });
  };
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const handleOpenChange2 = (newOpen) => {
    if (notification.length > 0) {
      setOpen2(newOpen);
    } else {
      setOpen2(false);
    }
  };
  const [year, setYear] = useState("2023");
  const [period, setPeriod] = useState("January");
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setYear(value);
    history.push({
      pathname: history.location.pathname,
      search: `?year=${value}&period=${period}`,
    });
  };
  const dispatch = useDispatch();

  const handleChange1 = (value) => {
    console.log(`selected ${value}`);
    setPeriod(value);
    history.push({
      pathname: history.location.pathname,
      search: `?year=${year}&period=${value}`,
    });
  };
  const names = user?.userName.split(" ");
  const firstLetters = names.map((name) => name.charAt(0));

  const GetNotifications = async () => {
    try {
      const result = await getNotificationByUserId(user?._id);
      setNotification(result?.documents);
      setNotificationCount(
        result?.documents.filter((item) => item.view === false).length
      );
      if (user.role === "admin") {
        FilteredNotifications();
      }
      console.log(result, "success");
    } catch (err) {
      console.log(err, "error");
    }
  };

  const DeleteNotification = async (id) => {
    try {
      const result = await deleteNotificationByUserId(id);
      if (result.success) {
        GetNotifications();
        setNotificationCount(notification?.filter((item) => item?.view === false).length);
        setOpen2(true);
      }
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const ViewUpdate = async (id) => {
    const body = {
      view: true,
    };
    try {
      const result = await editNotificationById(id, body);
      if (result.success) {
        GetNotifications();
        setNotificationCount(
          notification.filter((item) => item.view === false).length
        );
        setOpen2(false);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const FilteredNotifications = async (id) => {
    try {
      const result = await getNotificationByFilter();
      if (result.success) {
        setNotification((prevNotifications) => [
          ...prevNotifications,
          ...result?.documents,
        ]);

        const unseenNotificationsCount = result.documents.filter(
          (item) => !item.view
        ).length;
        setNotificationCount(
          (prev) =>
            prev +
            result?.documents.filter((item) => item.view === false).length
        );

        setOpen2(false);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    GetNotifications();
  }, []);

  // useEffect(() => {
  //   const socket = io("http://localhost:3001");

  //   socket.on("connect", () => {
  //     socket.emit("storeSocketId", "6458e0224bcde42bbef794f6");
  //   });

  //   socket.on("notification", (arg) => {
  //     console.log(arg);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}

        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
        </div>
        <div className="ml-auto text-center">
          <div
            className="ml-30"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
           <div style={{ marginRight: "10px", marginTop: "8px" }}>
              <Popover
                placement="bottom"
                content={
                  <div>
                    {notification.map((data, index) => (
                      <Link
                        to={
                          user?.role === "service" ?
                          data?.type === "service"
                            ? `/admin/services/deloitte/detail/${data?.orderId}`
                            : null:
                          user?.role === "admin"
                            ? data?.type === "order"
                              ? `/admin/orders/detail/${data?.orderId}`
                              : data?.type === "offer"
                              ? `/admin/offers/${data?.orderId}`
                              : data?.type === "invest"
                              ? `/admin/details/invest/${data?.orderId}`
                              : data?.type === "rent"
                              ? `/admin/details/rental/${data?.orderId}`
                              : null
                            : data?.type === "order"
                            ? `/admin/orders/seller/detail/${data?.orderId}`
                            : data?.type === "offer"
                            ? `/admin/offerseller/${data?.orderId}`
                            : data?.type === "service"
                            ? `/admin/services/seller/detail/${data?.orderId}`
                            : null
                        }
                        key={index}
                      >
                        <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          marginBottom: '5px',
                          alignItems: 'center',
                          background: data?.view && '#e0e0e0',
                          borderRadius: '6px',
                        }}
                        onClick={() => {
                          ViewUpdate(data?._id);
                          setOpen2(false);
                        }}
                      >
                        {data?.productId?.images[0]?.fileType === 'video' ? (
                          <video
                            src={data?.productId?.images[0]?.url}
                            style={{
                              height: '40px',
                              width: '60px',
                              borderRadius: '6px',
                            }}
                          />
                        ) : (
                          <img
                            src={data?.productId?.images[0]?.url}
                            alt=''
                            style={{
                              height: '40px',
                              width: '50px',
                              borderRadius: '6px',
                            }}
                          />
                        )}
                        <p
                          style={{
                            width: '300px',
                            margin: '0px',
                            color: 'black',
                            cursor: 'pointer',
                          }}
                          title={data?.message}
                        >
                          {data?.message.length > 70
                            ? data?.message.slice(0, 70) + '...'
                            : data?.message}
                        </p>
                        <div
                          style={{ paddingRight: '10px', cursor: 'pointer' }}
                          onClick={() => {
                            DeleteNotification(data?._id);
                          }}
                        >
                          x
                        </div>{' '}
                      </div>
                        {index !== notification.length - 1 && (
                          <Divider style={{ margin: "0px 0px 4px 0px" }} />
                        )}
                      </Link>
                    ))}
                    {notification.length === 0 && <p>No notifications found</p>}
                  </div>
                }
                arrow={false}
                trigger="click"
                open={open2}
                onOpenChange={handleOpenChange2}
              >
                <IconButton>
                  <Badge
                    badgeContent={notificationCount}
                    max={10}
                    color="primary"
                  >
                    <IoNotifications />
                  </Badge>
                </IconButton>
              </Popover>
            </div> 

            <span>
              <Popover
                placement="bottom"
                content={
                  <div>
                    <div className="fwt d-flex gap align-items-center mb-2">
                      <Avatar src={user?.profileImage} />
                      {user.email}
                    </div>
                    <Divider style={{ margein: "0px 0px 4px 0px" }} />
                    <Link to="/admin/profile" onClick={() => setOpen(false)}>
                      <div className="font-weight-bold d-flex gap align-items-center mb-2">
                        <BiUserCircle
                          style={{ color: "#F2C744", fontSize: "25px" }}
                        />
                        Profile
                      </div>
                    </Link>
                    {user.role === "seller" && (
                      <Link
                        to={`/admin/mykyc/${user?._id}`}
                        onClick={() => setOpen(false)}
                      >
                        <div className="font-weight-bold d-flex gap align-items-center mb-2">
                          <AiOutlineFileText
                            style={{ color: "#F2C744", fontSize: "25px" }}
                          />
                          My Kyc
                        </div>
                      </Link>
                    )}
                  </div>
                }
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
              >
                {user?.profileImage ? (
                  <img
                    alt=""
                    src={user?.profileImage}
                    style={{
                      height: "60px",
                      width: "60px",
                      borderRadius: "50%",
                      cursor: "pointer",
                      boxShadow: "0px 1px 7px -4px rgba(0,0,0,0.75)",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <BiUserCircle
                    style={{
                      fontSize: "38px",
                      color: "#F2C744",
                      cursor: "pointer",
                      background: "#010440",
                      borderRadius: "30px",
                    }}
                  />
                )}
              </Popover>
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "1px",
                flexDirection: "column",
              }}
            >
              <span
                className="text-capitalize"
                style={{
                  fontSize: "15px",
                  color: "#010440",
                  fontWeight: "600",
                }}
              >
                {user?.userName}
              </span>
              <span
                className="text-capitalize"
                style={{
                  fontSize: "13px",
                  color: "#7e8299",
                  fontWeight: "600",
                }}
              >
                {user?.role === "user" ? "Admin" : user?.role === "service" ? "Service Provider" : user?.role}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="ml-auto ml-lg-0 d-flex gap">
          <div className="mr-4">
            <div>Year</div>
            <>
              <Select
                defaultValue="2023"
                style={{
                  width: "fit-content",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "2023",
                    label: "2023",
                  },
                  {
                    value: "2022",
                    label: "2022",
                  },
                  {
                    value: "2021",
                    label: "2021",
                  },
                  {
                    value: "2020",
                    label: "2020",
                  },
                  {
                    value: "2019",
                    label: "2019",
                  },
                ]}
              />
            </>
          </div>
          <div>
            <div>Month</div>
            <>
              <Select
                defaultValue="January"
                style={{
                  width: "fit-content",
                }}
                onChange={handleChange1}
                options={[
                  {
                    value: "January",
                    label: "January ",
                  },
                  {
                    value: "February",
                    label: "February ",
                  },
                  {
                    value: "March",
                    label: "March ",
                  },
                  {
                    value: "April",
                    label: "April ",
                  },
                  {
                    value: "May",
                    label: "May ",
                  },
                  {
                    value: "June",
                    label: "June ",
                  },
                  {
                    value: "July",
                    label: "July ",
                  },
                  {
                    value: "August",
                    label: "August ",
                  },
                  {
                    value: "September",
                    label: "September ",
                  },
                  {
                    value: "October",
                    label: "October ",
                  },
                  {
                    value: "November",
                    label: "November ",
                  },
                  {
                    value: "December",
                    label: "December ",
                  },
                ]}
              />
            </>
          </div>
        </div> */}
        {/* Info */}

        {/* Toolbar */}
        {/* <div className="d-flex align-items-center flex-wrap">
          <button
            type="button"
            className={`btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2`}
          >
            <span className="svg-icon svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            {` `}New Member
          </button>

          <QuickActions />

         //  Button 
          <button
            className="btn btn-primary btn-icon font-weight-bolds   "
            data-toggle="modal"
            data-target="#kt_chat_modal"
          >
            <span className="svg-icon svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group-chat.svg"
                )}
              />
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
}
