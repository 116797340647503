import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect,useHistory } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { Input, message } from "antd";
import { AdminForgotPassword, AdminResetPassword } from "../../../../services/ApiServices";

const initialValues = {
  email: "",
};

function ResetPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // requestPassword(values.email)
      //   .then(() => setIsRequested(true))
      //   .catch(() => {
      //     setIsRequested(false);
      // setSubmitting(false);
      //     setStatus(
      //       intl.formatMessage(
      //         { id: "AUTH.VALIDATION.NOT_FOUND" },
      //         { name: values.email }
      //       )
      //     );
      //   });
      forgotPass(values.email);
    },
  });
  const [email, setEmail] = useState();
  const [emailerr, setEmailerr] = useState();
  const [passreset, setPassreset] = useState();
  const [passreset1, setPassreset1] = useState();


  const [isValid, setIsValid] = useState(true);
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValid(validateEmail(e.target.value));
  };
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const codeValue = urlParams.get("code");
  console.log('codeValue', urlParams, codeValue)

  const forgotPass = async () => {
    let result, body;
    body = {
      confirmPassword: passreset1,
      newPassword: passreset
    }
    try {
      result = await AdminResetPassword(codeValue, body);
      if (result.success) {
        message.success(result.message);
        setPassreset('');
        setPassreset1('');
        history.push('/auth')
      } else if (!result.success) {
        message.success(result.error);
        setEmailerr('Please Enter a new password.');
      } else {
        console.log("Error");
        message.error(result.error);
        setEmailerr('Please Enter a new password.');
      }
    } catch (e) {
      console.log(e);
      message.error(e.message);
      setEmailerr(e.message);
    }
  }
  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block", padding: '20px', backgroundColor: '#ebedf340', boxShadow: '6px 6px 3px 0px #010440cc',borderRadius:'6px' }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            {/* <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div> */}
          </div>
          {/* <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          > */}
          {emailerr && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {emailerr}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <label>Password</label>
            <Input.Password size="large" placeholder="Password"
              // className={`form-control-solid`}
              style={{ borderColor: "rgb(36 173 219)", fontSize: '16px', padding: '8px' }}
              onChange={(e) => {
                setPassreset(e.target.value);
              }} />
          </div>
          <div className="form-group fv-plugins-icon-container">
            <label>Confirm Password</label>
            <Input.Password size="large" placeholder="Password"
              // className={`form-control-solid`}
              style={{ borderColor: "rgb(36 173 219)", fontSize: '16px', padding: '8px' }}
              onChange={(e) => {
                setPassreset1(e.target.value);
              }} />
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              // id="kt_login_forgot_submit"
              // type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              // disabled={formik.isSubmitting}
              onClick={() => {
                if (passreset === passreset1) {
                  forgotPass()
                } else {
                  setEmailerr('Password does not match ')
                }
              }}
            >
              Submit
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
          {/* </form> */}
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
