import React, { useEffect, useState } from "react";
import { getCounts, getMineralsdetails, getSellerOrderdetails } from "../../../services/ApiServices";
import "../dashboards/dashboard.css";
import {
  BaseTablesWidget1,
  BaseTablesWidget2,
  BaseTablesWidget6,
  StatsWidget11,
  StatsWidget10,
  ListsWidget8,
  ListsWidget10,
  ListsWidget14,
  AdvanceTablesWidget9,
  TilesWidget12,
  TilesWidget11,
} from "../widgets";
import { LineChart } from "../widgets/base-tables/lineChart";
import { TilesWidget16 } from "../widgets/tiles/TilesWidget16";
import { TilesWidget17 } from "../widgets/tiles/TilesWidget17";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SellerOrderCount } from "../widgets/tiles/SellerOrderCount";
import { SellerMineralCount } from "../widgets/tiles/SellerMineralCount";
import { ListsWidgetSeller } from "../widgets/lists/ListsWidgetSeller";

export function Demo3Dashboard() {
  const [users, setUser] = useState({});
  const [ordercount, setOrdercount] = useState(0);
  const [mineralcount, setMineralcount] = useState();
  const [machinerycount, setMachinerycount] = useState();
  const [investcount, setInvestcount] = useState();

  const token = localStorage.getItem("token");
  const totalcounts = (mineralcount + machinerycount + investcount) || '0';
  const [listLoading, setListloading] = useState(false);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  console.log(user, 'cg')
  const getCount = async () => {
    let result;
    setListloading(true);
    result = await getCounts(user?.projectId);
    try {
      if (result.success) {
        setUser(result);
        setListloading(false);
      } else {
        console.log("Error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOrderCount = async () => {
    let result;
    setListloading(true);
    result = await getSellerOrderdetails({ 'sellerId': user?._id, sort: -1 }, user?.projectId, token);
    try {
      if (result?.success) {
        setOrdercount(result?.orders);
        setListloading(false);
      } else {
        console.log("Error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getProductCount = async () => {
    let result;
    result = await getMineralsdetails({ 'filter': { 'type': "minerals" }, userId: user._id }, user?.projectId, token);
    try {
      if (result.success) {
        setMineralcount(result.product.totalDocs)
      } else {
        console.log("Error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getMachineryCount = async () => {
    let result;
    result = await getMineralsdetails({ 'filter': { 'type': "machinery" }, userId: user._id }, user?.projectId, token);
    try {
      if (result.success) {
        setMachinerycount(result.product.totalDocs)
      } else {
        console.log("Error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getInvestCount = async () => {
    let result;
    result = await getMineralsdetails({
      'filter': { 'type': "invest" }, userId: user._id
    }, user?.projectId, token);
    try {
      if (result.success) {
        setInvestcount(result.product.totalDocs)
      } else {
        console.log("Error");
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getCount();
    getOrderCount();

    getProductCount();
    getMachineryCount();
    getInvestCount();
  }, []);

  return (
    <>
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className="row mb-8 dash ">
        {/* <div className="col-xl-3">
          <TilesWidget12 user={user} loading={listLoading} />
        </div>{" "} */}

        {user?.role === 'seller' ?
          <>
            <div className="col-xl-4">
              <SellerOrderCount user={ordercount} loading={listLoading} />
            </div>
            <div className="col-xl-4">
              <SellerMineralCount user={totalcounts} loading={listLoading} />
            </div>
          </> :
          <>
            <div className="col-xl-4 ">
              <TilesWidget17 user={users} loading={listLoading} />
            </div>
            <div className="col-xl-4">
              <TilesWidget11 user={users} loading={listLoading} />
            </div>
            <div className="col-xl-4">
              <TilesWidget16 user={users} loading={listLoading} />
            </div>
          </>
        }
      </div>
      <div className="row mb-4">
        <div className="col-xl-6">
          <LineChart className="gutter-b" />
        </div>
        <div className="col-xl-6">
          {user?.role === 'seller' ?
            <ListsWidgetSeller className="gutter-b card-stretch"/> :
            <ListsWidget14 className="gutter-b card-stretch" />
          }
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {user?.role === 'seller' ? null :
        <div className="row">
          <div className="col-xl-12">
            <BaseTablesWidget6 className="gutter-b" />
          </div>

          {/* <div className="col-xl-5">
          <div className="row">
            <div className="col-xl-12">
              <StatsWidget11
                className="gutter-b"
                symbolShape="circle"
                baseColor="danger"
              />
            </div>
            <div className="col-xl-12">
              <StatsWidget10
                className="gutter-b"
                symbolShape="circle"
                baseColor="info"
              />
            </div>
          </div>
        </div> */}
        </div>
      }
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <AdvanceTablesWidget9 className="card-stretch gutter-b" />
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-xl-6">
          <ListsWidget10 className="card-stretch gutter-b" />
        </div>
        <div className="col-xl-6">
          <BaseTablesWidget1 className="card-stretch gutter-b" />
        </div>
      </div>
     */}
      {/* <div className="row">
        <div className="col-lg-4">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <BaseTablesWidget2 className="card-stretch gutter-b" />
        </div>
      </div> */}
    </>
  );
}
