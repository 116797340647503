/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { getCounts } from "../../../../services/ApiServices";
import { toAbsoluteUrl } from "../../../_helpers";
import { BiCategory } from "react-icons/bi";

export function SellerMineralCount(
  props,
  { className, iconColor = "success", widgetHeight = "150px" }
) {
  return (
    <>
      <div
        className={` card card-custom ${className}`}
        style={{
          height: widgetHeight,
          backgroundImage: "linear-gradient(#F2C744,#F2D541)",
        }}
      >
        <div className="card-body text-white">
          <span className="svg-icon svg-icon-3x  ml-n2 text-white">
            <BiCategory />
          </span>
          <div className="font-weight-bolder font-size-h2 mt-3">
            {props.loading ? (
              <div
                className={`text-inverse-white spinner spinner-white font-weight-bolder font-size-h2 mb-3 mt-3 py-4`}
              ></div>
            ) : (
              props.user
            )}
          </div>

          <a
            href="admin/seller/orders"
            className="text-white text-hover-primary font-weight-bold font-size-lg mt-1"
          >
            Products
          </a>
        </div>
      </div>
    </>
  );
}
