import React from "react";
import ReactApexChart from "react-apexcharts";
import "./styles.css";

const Barchart = (props) => {
  const data = {
    options: {
      labels: ["Ginning", "Spinning", "Weaving", "Knitting"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return Number(val).toLocaleString();
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: ["Ginning", "Spinning", "Weaving", "Knitting"],
        position: "bottom",
        labels: {
          offsetY: 0,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs_: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
          offsetY: -35,
        },
      },
      fill: {
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100],
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function(val) {
            return Number(val).toLocaleString();
          },
        },
      },
      title: {
        // text: "Revenus des 12 derniers mois",
        floating: true,
        offsetY: 0,
        align: "center",
        style: {
          color: "#444",
        },
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // console.log(config.w.config.labels[config.dataPointIndex]);
            console.log("click unit");
            props.getType(config.w.config.labels[config.dataPointIndex]);
          },
        },
      },
    },

    series: [
      {
        name: [],
        data: [45, 10, 25, 32],
      },
    ],
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height="300"
      />
    </div>
  );
};

export default Barchart;
