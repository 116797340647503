import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register, registerByadmin } from "../_redux/authCrud";
import { useToasts } from "react-toast-notifications";
import { Form, Input } from "antd";
import "./index.css";
import { Checkbox } from "antd";
import PhoneInput from "react-phone-number-input";
import { countries } from "countries-list";
import "react-phone-number-input/style.css";
import Swal from "sweetalert2";
import { Mobile } from "../../../../hooks/mobile";

const initialValues = {
  email: "",
  firstName: "",
  password: "",
  lastName: "",
  phone: "",
  country: "",
  confirmPassword: "",
  sales: false,
  invest: false,
  radioButton: "",
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    firstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("First name is required"),
    lastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Last name is required"),
    phone: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Phone number is required"),
    country: Yup.string().required("Country is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    sales: Yup.bool().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    invest: Yup.bool().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    radioButton: Yup.string().required("Please select an option"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: async (values) => {
      if (values.invest || values.sales) {
        setLoading(true);
        let result = await registerByadmin(
          values.firstName,
          values.lastName,
          values.phone,
          values.country,
          values.email,
          values.password,
          values.invest,
          values.sales,
          values.radioButton
        );

        if (result.success) {
          localStorage.setItem("token", result.token);
          props.register(result.token);
          setLoading(false);
          history.push("/auth/login");
          Swal.fire({
            title: "Congratulations",
            text:
              "Thank you for signing up with us. Your record is under review. We will contact you as soon as possible.",
            icon: "success",

            confirmButtonColor: "#080356",

            confirmButtonText: "Ok",
          });
        } else {
          setLoading(false);
          return addToast(result.message, {
            appearance: "error",
            autoDismiss: "true",
          });
        }
      }
    },
  });
  const isSmallScreen = Mobile();
  const { addToast } = useToasts();
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [actypeError, setActypeError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [funError, setFunError] = useState("");
  const [passError, setPassError] = useState("");
  const [userError, setUserNameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [countryError, setCountryError] = useState("");

  const [investment, setInvestment] = useState(false);
  const [sales, setSales] = useState(false);
  const history = useHistory();
  // const countryOptions = Object.values(countries).map((country) => (
  //   <option key={country.name} value={country.name}>
  //     {country.name}
  //   </option>
  // ));
  const countryOptions = Object.keys(countries).map((countryCode) => (
    <option key={countryCode} value={countryCode}>
      {countries[countryCode].name}
    </option>
  ));

  const onSubmit = async () => {
    if (!firstName) {
      setUserNameError("please enter First name");
    } else if (!email) {
      // setUserNameError("");
      setError("please enter the email");
    } else if (!lastName) {
      // setUserNameError("");
      setLnameError("please enter Last name");
    } else if (!phone) {
      // setUserNameError("");
      setPhoneError("please enter Phone number");
    } else if (!country) {
      // setUserNameError("");
      setCountryError("please enter Country");
    } else if (!password) {
      setPassError("please enter the password");
    } else if (!confirmPassword) {
      setFunError("please confirm the  password");
    } else if (!sales || !investment) {
      setActypeError("please select account type");
    } else {
      setFunError("");
      setLoading(true);
      let result = await registerByadmin(
        firstName,
        lastName,
        phone,
        country,
        email,
        password,
        investment,
        sales
      );

      if (result.success) {
        localStorage.setItem("token", result.token);
        console.log(result, "success");
        props.register(result.token);
        setLoading(false);
        history.push("/auth/login");
        Swal.fire({
          title: "Congratulations",
          text:
            "Thank you for signing up with us. Your record is under review. We will contact you as soon as possible.",
          icon: "success",

          confirmButtonColor: "#080356",

          confirmButtonText: "Ok",
        });
      } else {
        setLoading(false);

        console.log("error in register");
        return addToast(result.message, {
          appearance: "error",
          autoDismiss: "true",
        });
      }
    }
  };
  const emailFunction = async (e) => {
    setError("");
    setEmailError("");
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmail("");
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(e.target.value);
    }
  };
  const passwordFunction = async (e) => {
    // setError("");
    // setEmailError("");
    setPassError("");
    setPassword(e.target.value);
  };
  const onChange = (e) => {
    setInvestment(e.target.checked);
  };
  const onChange1 = (e) => {
    setSales(e.target.checked);
  };

  const confirmPasswordFunction = async (e) => {
    if (password === e.target.value) {
      // setError("");
      // setEmailError("");
      setConfirmPassword(e.target.value);
      setConfirmError("");
    } else {
      setConfirmError("please enter the same password");
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid $light-grey",
        borderRadius: "20px",
        padding: "11px 40px 0px 40px",
        background: "white",
        boxShadow: "0px 0px 7px -1px rgba(0,0,0,0.35)",
        width: isSmallScreen ? "100%" : "50%",
        // height: "96%"
      }}
    >
      <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-10 ">
          <h3 className="font-size-h1" style={{ color: "#070550" }}>
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
          <p className="font-weight-bold">
            Enter your details to create your account
          </p>
        </div>

        {/* <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Full name"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "fullname"
          )}`}
          name="fullname"
        />
      </div> */}
        <form onSubmit={formik.handleSubmit}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="form-group fv-plugins-icon-container">
              <Input
                placeholder="First Name"
                type="text"
                // className={`form-control form-control-solid h-auto py-5 px-6`}
                size="large"
                style={{ borderColor: "rgb(36 173 219)", width: "92%" }}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-danger">{formik.errors.firstName}</div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <Input
                placeholder="Last Name"
                type="text"
                size="large"
                style={{ borderColor: "rgb(36 173 219)", width: "100%" }}
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="text-danger">{formik.errors.lastName}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group fv-plugins-icon-container">
            <PhoneInput
              name="phone"
              fd
              defaultCountry="IE"
              placeholder="Enter phone number"
              style={{
                width: "100%",
                height: "47px",
                border: "1px solid #3699ff",
                padding: "10px",
              }}
              value={formik.values.phone}
              onChange={(value) => formik.setFieldValue("phone", value)}
              onBlur={() => formik.setFieldTouched("phone", true)}
            />

            {formik.touched.phone && formik.errors.phone ? (
              <div className="text-danger">{formik.errors.phone}</div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <select
              style={{
                border: "1px solid rgb(36 173 219)",
                height: "41px",
                fontWeight: "400",
                width: "100%",
                fontFamily: "poppins",
                fontSize: "14px",
              }}
              value={formik.values.country}
              onChange={(selectedOption) => {
                formik.setFieldValue("country", selectedOption.target.value);
                console.log(selectedOption.target.value, "country");
              }}
              onBlur={() => formik.setFieldTouched("country", true)}
            >
              <option value="">Country</option>
              {countryOptions}
            </select>
            {formik.touched.country && formik.errors.country ? (
              <div className="text-danger">{formik.errors.country}</div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <Input
              placeholder="Email"
              type="email"
              size="large"
              style={{ borderColor: "rgb(36 173 219)" }}
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </div>

          {/* end: Email */}

          {/* begin: userName */}

          {/* end: userName */}

          {/* begin: Password */}
          <div className="form-group fv-plugins-icon-container">
            <Input.Password
              size="large"
              placeholder="Password"
              // className={`form-control-solid`}
              style={{
                borderColor: "rgb(36 173 219)",
                fontSize: "16px",
                padding: "8px",
              }}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {/* <Input
            placeholder="Password"
            type="password"
            size="large"
            style={{ borderColor: "rgb(36 173 219)" }}
            name="password"
            onChange={(e) => {
              passwordFunction(e);
            }}
          /> */}

            {formik.touched.password && formik.errors.password ? (
              <div className="text-danger">{formik.errors.password}</div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <Input.Password
              size="large"
              placeholder="Confirm Password"
              style={{
                borderColor: "rgb(36 173 219)",
                fontSize: "16px",
                padding: "8px",
              }}
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-danger">{formik.errors.confirmPassword}</div>
            ) : null}
          </div>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radioButton"
              value={formik.values.radioButton}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <FormControlLabel
                value="seller"
                control={<Radio />}
                label="Are you the seller ?"
              />
              <FormControlLabel
                value="broker"
                control={<Radio />}
                label="Are you a broker ?"
              />
            </RadioGroup>
            {formik.touched.radioButton && formik.errors.radioButton ? (
              <div className="text-danger">{formik.errors.radioButton}</div>
            ) : null}
          </FormControl>

          <div>
            <div
              className="form-group font-weight-bold"
              style={{ fontSize: "16px" }}
            >
              I want to
            </div>
            <div className="form-group" style={{ marginTop: "-14px" }}>
              <Checkbox
                style={{
                  borderColor: "rgb(36 173 219) !important",
                  fontWeight: "500",
                  border: "1px",
                  marginTop: "-14px",
                }}
                name="invest"
                checked={formik.values.invest}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                List investment projects
              </Checkbox>
            </div>
            <div className="form-group" style={{ marginTop: "-14px" }}>
              <Checkbox
                style={{ borderColor: "rgb(36 173 219)", fontWeight: "500" }}
                name="sales"
                checked={formik.values.sales}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                Sell minerals or machineries
              </Checkbox>
            </div>
          </div>

          {!formik.values.invest &&
          !formik.values.sales &&
          formik.touched.invest &&
          formik.touched.sales ? (
            <div className="text-danger">
              You must select at least one checkbox
            </div>
          ) : null}

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              style={{ backgroundColor: "#010440", color: "#f0d03d" }}
            >
              Submit
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>

            <Link to="/auth/login">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                style={{ color: "010440", border: "none" }}
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </Box>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
