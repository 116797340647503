/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";

import { SettingsOutlined } from "@material-ui/icons";
import { GrUserSettings } from "react-icons/gr";
import { useToasts } from "react-toast-notifications";
import { SiContactlesspayment, SiMoneygram } from "react-icons/si";

import "../index.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { checkIsActive } from "../../../../_helpers";
import { LOGO } from "../../../../../Config/config";
import {
  MdForum,
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowRight,
  MdLayers,
  MdOutlineForum,
  MdOutlineHomeRepairService,
  MdOutlinePayments,
  MdProductionQuantityLimits,
  MdSettings,
} from "react-icons/md";

import { FiLogOut } from "react-icons/fi";
import { BiCategoryAlt, BiSolidUser, BiUserCircle } from "react-icons/bi";
import { BsFiletypeDoc, BsFillBuildingsFill } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";

import { AiOutlineUserAdd } from "react-icons/ai";
import { ImFeed } from "react-icons/im";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { storage } from "../../../../../services/firebaseConfig";

import "../index.css";
import { profileUpdate } from "../../../../../services/ApiServices";

import * as actions from "../../../../../app/modules/ECommerce/_redux/categoriesAction/collectionsActions";

import { Menu } from "antd";
import { useEffect } from "react";
import { RiFeedbackLine } from "react-icons/ri";
import { FaRegCommentDots, FaRegHandshake, FaUsers } from "react-icons/fa";
import SmartLogo from "../CompanyLogo/CompanyLogo-NoBg.png";
import { GiBulldozer, GiMinerals } from "react-icons/gi";
import { IoDocumentAttachOutline } from "react-icons/io5";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export function AsideMenuList({ layoutProps }) {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState();
  const [images, setImages] = useState();

  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const update = async () => {
    if (url) {
      let body = {
        profileImage: url,
      };
      let result;
      try {
        result = await profileUpdate(user?._id, body, user?.projectId);
        if (result.success) {
          return addToast("Successfully Uploaded", {
            appearance: "success",
            autoDismiss: "true",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { SubMenu } = Menu;

  const [openKeys, setOpenKeys] = useState([]);
  const [activeKey, setActiveKey] = useState(null);

  const option1 = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: <MdLayers color="#F2C744" />,
      route: "/dashboard",
    },
  ];
  const items123 = [
    {
      key: "catelogue",
      label: "Catalogue",
      icon: <BiCategoryAlt color="#F2C744" size={18} />,
      children: [
        {
          key: "2",
          label: "Minerals",
          icon: <GiMinerals color="#F2C744" />,
          route: "/admin/minerals/seller",
        },
        {
          key: "3",
          label: "Machineries",
          icon: <GiBulldozer color="#F2C744" />,
          route: "/admin/machinery/seller",
        },
      ],
    },
    {
      key: "sales",
      label: "Sales",
      icon: <SiMoneygram color="#F2C744" size={18} />,
      children: [
        {
          key: "4",
          label: "Orders",
          icon: <BsFillBuildingsFill color="#F2C744" />,
          route: "/admin/seller/orders",
        },
        {
          key: "5",
          label: "Offers",
          icon: <BiUserCircle color="#F2C744" />,
          route: "/admin/seller/offers",
        },
      ],
    },
  ];

  const [activeKeys, setActiveKeys] = useState([]);
  const findActiveKeys = (menuItems, pathname) => {
    for (const item of menuItems) {
      if (item.children) {
        const foundChild = item.children.find(
          (child) => child.route === pathname
        );
        if (foundChild) {
          setActiveKeys([item.key, foundChild.key]);
          localStorage.setItem("activeKey", foundChild.key);
          return;
        }
      } else if (item.route === pathname) {
        setActiveKeys([item.key]);
        localStorage.setItem("activeKey", item.key);
        return;
      }
    }
    setActiveKeys([]);
  };

  useEffect(() => {
    const pathname = location.pathname;

    // Find the active key based on the current URL
    const foundMenuItem = items123.find(
      (menuItem) => menuItem.route === pathname
    );

    if (foundMenuItem) {
      setActiveKey(foundMenuItem.key);

      // Store active key in local storage
      localStorage.setItem("activeKey", foundMenuItem.key);
    }
  }, [location]);

  useEffect(() => {
    findActiveKeys(items123, location.pathname);
    if (activeKeys) {
      setActiveKeys(localStorage.getItem("activeKey"));
    } else {
      localStorage.setItem("activeKey", 0);
    }
  }, [location, activeKeys]);

  const items = [
    {
      key: "catelog",
      title: "Catalogue",
      icon: <BiCategoryAlt color="#F2C744" size={18} />,
      subMenuItems: [
        // {
        //   key: "6",
        //   title: "Products",
        //   url: "/admin/products",
        //   icon: <MdProductionQuantityLimits />,
        // },
        // {
        //   key: "7",
        //   title: "Categories",
        //   url: "/admin/categories",
        //   icon: <BiCategory />,
        // },

        // {
        //   key: "12",
        //   title: "Offers",
        //   url: "/admin/offers",
        //   icon: <BiCategory />,
        // },
        {
          key: "23",
          title: "Minerals",
          url: "/admin/minerals/products",
          icon: <GiMinerals color="#F2C744" />,
        },
        {
          key: "24",
          title: "Machineries",
          url: "/admin/machinery/products",
          icon: <GiBulldozer color="#F2C744" />,
        },
        {
          key: "24",
          title: "Platform Services",
          url: "/admin/Platformservice",
          icon: <MdOutlineHomeRepairService color="#F2C744" />,
        },

        {
          key: "25",
          title: "Investment Projects",
          url: "/admin/invest/products",
          icon: <BiCategory color="#F2C744" />,
        },
        {
          key: "26",
          title: "Precious Stones & Collectibles",
          url: "/admin/precious/products",
          icon: <BiCategory color="#F2C744" />,
        },
      ],
    },
    {
      key: "platform",
      title: "Platform Services",
      icon: <MdOutlineHomeRepairService color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "50",
          title: "Minerals",
          url: "/admin/platform-services/minerals/products",
          icon: <GiMinerals color="#F2C744" />,
        },
        {
          key: "51",
          title: "Machineries",
          url: "/admin/platform-services/machinery/products",
          icon: <GiBulldozer color="#F2C744" />,
        },
        {
          key: "52",
          title: "Investment Projects",
          url: "/admin/platform-services/invest/products",
          icon: <BiCategory color="#F2C744" />,
        },
        {
          key: "53",
          title: "Precious Stones & Collectibles",
          url: "/admin/platform-services/precious/products",
          icon: <BiCategory color="#F2C744" />,
        },
      ],
    },
    {
      key: "sales",
      title: "Sales",
      icon: <SiMoneygram color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/orders",
          icon: <BsFillBuildingsFill color="#F2C744" />,
        },
        {
          key: "12",
          title: "Offers",
          url: "/admin/offers",
          icon: <BiCategory color="#F2C744" />,
        },
        {
          key: "12",
          title: "Services",
          // url: "/admin/offers",
          icon: <MdOutlineHomeRepairService color="#F2C744" />,
        },
        // {
        //   key: "10",
        //   title: "Customers",
        //   url: "/admin/users",
        //   icon: <BiUserCircle />,
        // },
        // {
        //   key: "11",
        //   title: "Payments",
        //   url: "/admin/payment",
        //   icon: <SiContactlesspayment color="#F2C744" />,
        // },
        // {
        //   key: "12",
        //   title: "Invest Transactions",
        //   url: "/admin/transactions/invest",
        //   icon: <SiContactlesspayment color="#F2C744" />,
        // },
        {
          key: "19",
          title: "Investment",
          url: "/admin/investment",
          icon: <SiContactlesspayment color="#F2C744" />,
        },
        {
          key: "20",
          title: "Rental & Leases",
          url: "/admin/transactions/invest",
          icon: <SiContactlesspayment color="#F2C744" />,
        },
      ],
    },
    {
      key: "forums",
      title: "Forums & Discussion",
      icon: <MdOutlineForum color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "16",
          title: "Forums",
          url: "/admin/Forums",
          icon: <BiUserCircle color="#F2C744" />,
        },
        {
          key: "15",
          title: "Forum-Requests",
          url: "/admin/ForumRequests",
          icon: <MdForum color="#F2C744" />,
        },

        // {
        //   key: "17",
        //   title: "Payments",
        //   url: "/admin/payment",
        //   icon: <SiContactlesspayment />,
        // },
      ],
    },
    // {
    //   key: "sub1",
    //   title: "Settings",
    //   icon: <MdSettings color="#F2C744" size={18}/>,
    //   subMenuItems: [
    //     {
    //       key: "17",
    //       title: "Notifications",
    //       url: "/admin/config/notification",
    //       icon: <MdOutlineNotificationsActive color="#F2C744"/>,
    //     },
    //     {
    //       key: "18",
    //       title: "Product Types",
    //       url: "/admin/config/Producttypes",
    //       icon: <MdOutlineNotificationsActive color="#F2C744"/>,
    //     },
    //     {
    //       key: "19",
    //       title: "Payment Gateway",
    //       url: "/admin/config/Paymentgateway",
    //       icon: <MdOutlinePayments color="#F2C744"/>,
    //     },
    //     {
    //       key: "20",
    //       title: "System Config",
    //       url: "/admin/configuration",
    //       icon: <ImFeed color="#F2C744"/>,
    //     },

    //     {
    //       key: "21",
    //       title: "Users Manual",
    //       url: "/admin/manual",
    //       icon: <GrUserSettings color="#F2C744"/>,
    //     },
    //   ],
    // },
    {
      key: "user",
      title: "Users",
      icon: <FaUsers color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "22",
          title: "Buyers/Investors",
          url: "/admin/users",
          icon: <BiSolidUser color="#F2C744" />,
        },
        {
          key: "22",
          title: "Sellers",
          url: "/admin/sellers",
          icon: <BiSolidUser color="#F2C744" />,
        },
        {
          key: "22",
          title: "Service Providers",
          // url: "/admin/sellers",
          icon: <MdOutlineHomeRepairService color="#F2C744" />,
        },
        {
          key: "24",
          title: "NCNDA",
          url: "/admin/ncnda",
          icon: <IoDocumentAttachOutline color="#F2C744" />,
        },
        {
          key: "24",
          title: "Platform Agreement  ",
          url: "/admin/platformagreement",
          icon: <FaRegHandshake color="#F2C744" />,
        },
      ],
    },
    // {
    //   key: "ncnda",
    //   title: "NCNDA",
    //   icon: <IoDocumentAttachOutline color="#F2C744" size={18}/>,
    //   subMenuItems: [
    //     {
    //       key: "24",
    //       title: "NCNDA",
    //       url: "/admin/ncnda",
    //       icon: <IoDocumentAttachOutline color="#F2C744" size={15}/>,
    //     }
    //   ],
    // },
  ];

  const sellerItems = [
    {
      key: "catelog",
      title: "Catalogue",
      icon: <BiCategoryAlt color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "6",
          title: "Minerals",
          url: "/admin/minerals/seller",
          icon: <GiMinerals color="#F2C744" />,
        },
        {
          key: "7",
          title: "Machineries",
          url: "/admin/machinery/seller",
          icon: <GiBulldozer color="#F2C744" />,
        },

        // {
        //   key: "12",
        //   title: "Projects",
        //   url: "/admin/invest/seller",
        //   icon: <BiCategory color="#F2C744" />,
        // },
      ],
    },
    {
      key: "sales",
      title: "Sales",
      icon: <SiMoneygram color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/seller/orders",
          icon: <BsFillBuildingsFill color="#F2C744" />,
        },
        {
          key: "10",
          title: "Offers",
          url: "/admin/seller/offers",
          icon: <BiUserCircle color="#F2C744" />,
        },
        // {
        //   key: "12",
        //   title: "Invest Transactions",
        //   url: "/admin/transactions/seller/invest",
        //   icon: <SiContactlesspayment color="#F2C744" />,
        // },
        // {
        //   key: "11",
        //   title: "Rentals",
        //   // url: "/",
        //   icon: <SiContactlesspayment color="#F2C744" />,
        // },
        // {
        //   key: "12",
        //   title: "Invest",
        //   // url: "/admin/investments/seller/invest",
        //   // url: "/",
        //   icon: <SiContactlesspayment color="#F2C744" />,
        // },
      ],
    },
  ];

  const ServiceProviderItems = [
    {
      key: "catelog",
      title: "Service Catalogue",
      icon: <BiCategoryAlt color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "6",
          title: "Minerals",
          url: "/admin/minerals/seller",
          icon: <GiMinerals color="#F2C744" />,
        },
        {
          key: "7",
          title: "Machineries",
          url: "/admin/machinery/seller",
          icon: <GiBulldozer color="#F2C744" />,
        },
      ],
    },
    {
      key: "request",
      title: "Service Requests",
      icon: <SiMoneygram color="#F2C744" size={18} />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/seller/orders",
          icon: <BsFillBuildingsFill color="#F2C744" />,
        },
        {
          key: "10",
          title: "Offers",
          url: "/admin/seller/offers",
          icon: <BiUserCircle color="#F2C744" />,
        },
      ],
    },
  ];

  const miningitems = [
    {
      key: "catelog",
      title: "Catalogue",
      icon: <BiCategoryAlt />,
      subMenuItems: [
        {
          key: "6",
          title: "Products",
          url: "/admin/products",
          icon: <MdProductionQuantityLimits />,
        },
        {
          key: "7",
          title: "Categories",
          url: "/admin/categories",
          icon: <BiCategory />,
        },
      ],
    },
    {
      key: "sales",
      title: "My Sales",
      icon: <SiMoneygram />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/orders",
          icon: <BsFillBuildingsFill />,
        },
        {
          key: "10",
          title: "Customers",
          url: "/admin/users",
          icon: <BiUserCircle />,
        },
        {
          key: "11",
          title: "Payments",
          url: "/admin/payment",
          icon: <SiContactlesspayment />,
        },
      ],
    },
    {
      key: "sub1",
      title: "Settings",
      icon: <SettingsOutlined />,
      subMenuItems: [
        {
          key: "1",
          title: "Notifications",
          url: "/admin/config/notification",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "2",
          title: "Product Types",
          url: "/admin/config/Producttypes",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "3",
          title: "Payment Gateway",
          url: "/admin/config/Paymentgateway",
          icon: <MdOutlinePayments />,
        },
        {
          key: "4",
          title: "System Config",
          url: "/admin/configuration",
          icon: <ImFeed />,
        },

        {
          key: "5",
          title: "Users Manual",
          url: "/admin/manual",
          icon: <GrUserSettings />,
        },
      ],
    },
  ];
  const stmitems = [
    {
      key: "catelog",
      title: "Catalogue",
      icon: <BiCategoryAlt />,
      subMenuItems: [
        {
          key: "6",
          title: "Products",
          url: "/admin/products",
          icon: <MdProductionQuantityLimits />,
        },
        {
          key: "7",
          title: "Categories",
          url: "/admin/categories",
          icon: <BiCategory />,
        },
      ],
    },
    {
      key: "sales",
      title: "Sales",
      icon: <SiMoneygram />,
      subMenuItems: [
        {
          key: "9",
          title: "Orders",
          url: "/admin/orders",
          icon: <BsFillBuildingsFill />,
        },
        {
          key: "10",
          title: "Customers",
          url: "/admin/users",
          icon: <BiUserCircle />,
        },
        {
          key: "11",
          title: "Payments",
          url: "/admin/payment",
          icon: <SiContactlesspayment />,
        },
      ],
    },
    {
      key: "sub1",
      title: "Settings",
      icon: <SettingsOutlined />,
      subMenuItems: [
        {
          key: "1",
          title: "Notifications",
          url: "/admin/config/notification",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "2",
          title: "Product Types",
          url: "/admin/config/Producttypes",
          icon: <MdOutlineNotificationsActive />,
        },
        {
          key: "3",
          title: "Payment Gateway",
          url: "/admin/config/Paymentgateway",
          icon: <MdOutlinePayments />,
        },
        {
          key: "4",
          title: "System Config",
          url: "/admin/configuration",
          icon: <ImFeed />,
        },

        {
          key: "5",
          title: "Users Manual",
          url: "/admin/manual",
          icon: <GrUserSettings />,
        },
      ],
    },
    {
      key: "feedback",
      title: "Feedback",
      icon: <RiFeedbackLine />,
      subMenuItems: [
        {
          key: "1",
          title: "Feedback",
          url: "/admin/feedback",
          icon: <FaRegCommentDots />,
        },
      ],
    },
  ];

  const handleClick = (url, key) => {
    history.push(url);
    setActiveKey(key);
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const findActiveKey = (url) => {
    for (const item of items) {
      for (const subItem of item.subMenuItems) {
        if (subItem.url === url) {
          return item.key;
        }
      }
    }
    return null;
  };
  // 648ff13fe0a4e803ae555e31
  // Set the active key based on the current URL
  useEffect(() => {
    const currentMenuItem = items.find((item) =>
      location.pathname.includes(item.title)
    );
    if (currentMenuItem) {
      setActiveKey(currentMenuItem.key);
    }
  }, [location.pathname]);

  console.log(location.pathname, "check");
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses} sidebar`}>
        {/*begin::1 Level*/}
        <Link to="/" className="flex-column-auto pb-lg-0 pb-10">
          {!images && (
            <img
              alt="Logo"
              className="d-flex mx-auto mb-5"
              src={
                SmartLogo
                // user.profileImage
                //   ? user.profileImage
                //   :
                // "https://firebasestorage.googleapis.com/v0/b/flexi-props-api.appspot.com/o/maramaladventures%2FWhatsApp%20Image%202023-05-11%20at%2015.39.43.jpg?alt=media&token=0e5d5118-94b2-4ddf-9b14-4bae6ecbffdc"
              }
              style={{ width: "60%", maxWidth: "60%" }}
            />
          )}
        </Link>
        {/* {!images && <li className=" admin ">Admin</li>} */}

        <li
          style={{ top: "12px" }}
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink
            style={{ display: "flex", alignItems: "center", gap: "7px" }}
            className="menu-link"
            to="/dashboard"
          >
            {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
            <MdLayers color="#F2C744" size={20} />

            <span style={{ fontSize: "14px" }} className="menu-text">
              Dashboard
            </span>
          </NavLink>
        </li>

        <hr></hr>
        {/* <li
          className={`menu-item ${getMenuItemActive("/admin/adminDetails", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/adminDetails">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Pencil.svg")} />
            </span>
            <span className="menu-text">Admin</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/admin/user", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/user">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/general/User.svg")} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/admin/empuser", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/empuser">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/* {user.projectId === "648ff13fe0a4e803ae555e31" ? (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname]}
          >
            {items.map((item) => (
              <SubMenu key={item.key} title={item.title} icon={item.icon}>
                {item.subMenuItems.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    onClick={() => {
                      handleClick(subItem.url, item.key);
                    }}
                    icon={subItem.icon}
                    className={
                      location.pathname.indexOf(subItem.url) === 0
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    {subItem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        ) : user.projectId === "648701c78a0d5cb83ec3b20a" ? (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname]}
          >
            {stmitems.map((item) => (
              <SubMenu key={item.key} title={item.title} icon={item.icon}>
                {item.subMenuItems.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    onClick={() => {
                      handleClick(subItem.url, item.key);
                    }}
                    icon={subItem.icon}
                    className={
                      location.pathname.indexOf(subItem.url) === 0
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    {subItem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        ) : (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname]}
          >
            {miningitems.map((item) => (
              <SubMenu key={item.key} title={item.title} icon={item.icon}>
                {item.subMenuItems.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    onClick={() => {
                      handleClick(subItem.url, item.key);
                    }}
                    icon={subItem.icon}
                    className={
                      location.pathname.indexOf(subItem.url) === 0
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    {subItem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        )} */}

        {user?.role === "seller" ? (
          <>
            <Menu
              mode="inline"
              openKeys={openKeys}
              onOpenChange={handleOpenChange}
              selectedKeys={[location.pathname]}
            >
              {sellerItems.map((item) => (
                <SubMenu key={item.key} title={item.title} icon={item.icon}>
                  {item.subMenuItems.map((subItem) => (
                    <Menu.Item
                      key={subItem.key}
                      onClick={() => {
                        handleClick(subItem.url, item.key);
                      }}
                      icon={subItem.icon}
                    >
                      {subItem.title}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
            </Menu>

            <li
              style={{ top: "12px" }}
              className={`menu-item ${getMenuItemActive(
                "/admin/seller/services",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
                className="menu-link"
                to="/admin/seller/services"
              >
                <MdOutlineHomeRepairService color="#F2C744" size={20} />
                <span style={{ fontSize: "14px" }} className="menu-text">
                  Services
                </span>
              </NavLink>
            </li>
            {/* <Menu
              style={{
                width: 256,
              }}
              mode="inline"
              selectedKeys={activeKeys}
            >
              {items123.map((menuItem) => (
                <Menu.SubMenu
                  key={menuItem.key}
                  title={menuItem.label}
                  icon={menuItem.icon}
                  className={activeKeys === menuItem.key ? "activeSubMenu" : ""}
                >
                  {menuItem.children.map((subMenuItem) => (
                    <Menu.Item key={subMenuItem.key} icon={subMenuItem.icon}>
                      <Link
                        to={subMenuItem.route}
                        style={{
                          color:
                            activeKeys === menuItem.key ? "black" : "black",
                        }}
                        className={
                          activeKeys === subMenuItem.key ? "activeLink" : ""
                        }
                      >
                        {subMenuItem.label}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ))}
            </Menu> */}
          </>
        ) : user?.role === "service" ? (
          <>
            <li
              style={{ top: "12px" }}
              className={`menu-item ${getMenuItemActive(
                "/admin/Platformservice",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
                className="menu-link"
                to="/admin/Platformservice"
              >
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                <BiCategoryAlt color="#F2C744" size={20} />

                <span style={{ fontSize: "14px" }} className="menu-text">
                  Service Catalogue
                </span>
              </NavLink>
            </li>

            <li
              style={{ top: "12px" }}
              className={`menu-item mt-4 ${getMenuItemActive(
                "/admin/deloitte/services",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
                className="menu-link"
                to="/admin/deloitte/services"
              >
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                <SiMoneygram color="#F2C744" size={20} />

                <span style={{ fontSize: "14px" }} className="menu-text">
                  Service Requests
                </span>
              </NavLink>
            </li>
          </>
        ) : (
          // <Menu
          //   mode="inline"
          //   openKeys={openKeys}
          //   onOpenChange={handleOpenChange}
          //   selectedKeys={[location.pathname]}
          // >
          //   {ServiceProviderItems.map((item) => (
          //     <SubMenu key={item.key} title={item.title} icon={item.icon}>
          //       {item.subMenuItems.map((subItem) => (
          //         <Menu.Item
          //           key={subItem.key}
          //           onClick={() => {
          //             handleClick(subItem.url, item.key);
          //           }}
          //           icon={subItem.icon}
          //           className={
          //             location.pathname.indexOf(subItem.url) === 0
          //               ? "ant-menu-item-selected"
          //               : ""
          //           }
          //         >
          //           {subItem.title}
          //         </Menu.Item>
          //       ))}
          //     </SubMenu>
          //   ))}
          // </Menu>
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname]}
          >
            {items.map((item) => (
              <SubMenu key={item.key} title={item.title} icon={item.icon}>
                {item.subMenuItems.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    onClick={() => {
                      handleClick(subItem.url, item.key);
                    }}
                    icon={subItem.icon}
                    className={
                      location.pathname.indexOf(subItem.url) === 0
                        ? "ant-menu-item-selected"
                        : ""
                    }
                  >
                    {subItem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        )}
        <hr />
        {/* <li
          className={`menu-item ${getMenuItemActive("/admin/docs")},           
          ${getMenuItemActive(`/admin/docs/detail/:id`)},
                `}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/docs">
            <span className="svg-icon menu-icon">
              
              <BsFiletypeDoc color="#F2C744"/>
            </span>
            <span className="menu-text"> Api Documentation</span>
          </NavLink>
        </li> */}
        <li
          onClick={() => {
            localStorage.removeItem("token");
            dispatch(actions.clearCategories({}));
          }}
          className={`menu-item ${getMenuItemActive("/logout", false)}`}
          aria-haspopup="true"
        >
          <NavLink
            style={{ display: "flex", alignItems: "center", gap: "7px" }}
            className="menu-link"
            to="/logout"
          >
            {/* <span className="svg-icon menu-icon"> */}
            {/* <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}
              /> */}
            <FiLogOut color="#F2C744" size={20} />
            {/* </span> */}
            <span style={{ fontSize: "14px" }} className="menu-text">
              Sign Out
            </span>
          </NavLink>
          {/* <NavLink
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </NavLink> */}
        </li>
        {show && (
          <>
            {/* <li className="menu-section  ">
              <h4 className="menu-text">Settings</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li> */}
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/adminDetails"
              )},           
          ${getMenuItemActive(`/admin/products/detail/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/adminDetails">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <AiOutlineUserAdd />
                </span>
                <span className="menu-text"> Users</span>
              </NavLink>
            </li>{" "}
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/notification"
              )},           
          ${getMenuItemActive(`/admin/configuration/notification/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/notification">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <MdOutlineNotificationsActive />
                </span>
                <span className="menu-text"> Notifications</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/Producttypes"
              )},           
          ${getMenuItemActive(`/admin/configuration/Producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/Producttypes">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <MdOutlineNotificationsActive />
                </span>
                <span className="menu-text"> Product types</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/Paymentgateway"
              )},           
          ${getMenuItemActive(`/admin/configuration/producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/Paymentgateway">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <MdOutlinePayments />
                </span>
                <span className="menu-text"> Payment Gateway</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/producttypes"
              )},           
          ${getMenuItemActive(`/admin/configuration/Producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/configuration">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <ImFeed />
                </span>
                <span className="menu-text"> System Config</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/config/Profile"
              )},           
          ${getMenuItemActive(`/admin/configuration/producttypes/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/config/Profile">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                </span>
                <span className="menu-text">Profile</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/manual"
              )},           
          ${getMenuItemActive(`/admin/manual/detail/:id`)},
                `}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admin/manual">
                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                  <GrUserSettings />
                </span>
                <span className="menu-text"> Users Manual</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

{
  /* <li
className={`menu-item menu-item-submenu ${getMenuItemActive(
  "/error",
  true
)}`}
aria-haspopup="true"
data-menu-toggle="hover"
>
<NavLink className="menu-link menu-toggle" to="/error">
  <span className="svg-icon menu-icon">
    <SVG
      src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
    />
  </span>
  <span className="menu-text">Error Pages</span>
  <i className="menu-arrow" />
</NavLink>
<div className="menu-submenu ">
  <i className="menu-arrow" />
  <ul className="menu-subnav">
    <li className="menu-item  menu-item-parent" aria-haspopup="true">
      <span className="menu-link">
        <span className="menu-text">Error Pages</span>
      </span>
    </li>

    <li
      className={`menu-item ${getMenuItemActive("/error/error-v1")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v1">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 1</span>
      </NavLink>
    </li>
   
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v2")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v2">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page -2</span>
      </NavLink>
    </li>
 
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v3">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 3</span>
      </NavLink>
    </li>
   
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v4")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v4">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 4</span>
      </NavLink>
    </li>

    <li
      className={`menu-item ${getMenuItemActive("/error/error-v5")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v5">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 5</span>
      </NavLink>
    </li>
 
    <li
      className={`menu-item ${getMenuItemActive("/error/error-v6")}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to="/error/error-v6">
        <i className="menu-bullet menu-bullet-dot">
          <span />
        </i>
        <span className="menu-text">Error Page - 6</span>
      </NavLink>
    </li>
  </ul>
</div>
</li> */
}
