//------------------------------------- LOGO -------------------------------------
export const LOGO = "/media/logos/SmartMiningLogo.png";

export const MOBILE_LOGO = "/media/logos/favicon.png";

export const AUTH_BACKGROUND = "/media/logos/bg1.jpeg";

export const PRODUCT_NAME = "Admin";

export const PRODUCT_YEAR = "2023";

export const WELCOME_HEADER = "WELCOME TO SMART MINING ADMIN";

export const WELCOME_TEXT =
  "The seller dashboard helps to interact with buyers and investors.";

export const SIGNATURE_MESSAGE_KEY = "kGFCxvda2GsWxe7DG6XW";

export const CONTRACT_ADDRESS =
  process.env.REACT_APP_CONTRACT_ADDRESS ||
  "0xfA4917204C77e472a259A90cD2ea7C7dDa6e7D90";
