const baseUrl =
  'https://e13l4ticy3.execute-api.eu-west-1.amazonaws.com/prod/v1/';
  // "https://v2o7d11u17.execute-api.eu-west-1.amazonaws.com/dev/v1/";
// "http://localhost:8000/v1/";
// const baseUrl =
// "http://127.0.0.1:5001/mining-app-46db1/us-central1/mining/v1/";
// "https://us-central1-mining-app-46db1.cloudfunctions.net/mining/v1/";

// -------------------- Asset --------------------
export async function GetAssets(params, body) {
  let response;
  try {
    response = await fetch("/v1/assets/filter" + params, {
      // response = await fetch("/v1/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAsset(id) {
  let response;
  try {
    response = await fetch("/v1/asset/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetsByCollection(id, params) {
  let response;
  try {
    response = await fetch("/v1/assets/collection/" + id + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetsByFilter(id, body) {
  let response;
  try {
    response = await fetch("/v1/assets/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetordsByFilter(id, body) {
  let response;
  try {
    response = await fetch("/v1/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ collectionId: id, type: body }),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- TOKEN ID --------------------
export async function GetTokenId() {
  let response;
  try {
    response = await fetch("/v1/asset/getTokenId", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- USER --------------------
export async function GetUsers(body) {
  let response;

  try {
    response = await fetch(`${baseUrl}users/filter`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getsample() {
  let response;
  try {
    response = await fetch(`https://hrms.wisebms.in/v1/apartment/details`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCount() {
  let response;
  try {
    response = await fetch(`${baseUrl}typeCount`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAdminUsers(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/AllUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateAdmin(body) {
  let response;
  try {
    response = await fetch("/v1/admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Login(body) {
  let response;
  try {
    response = await fetch(
      // "https://us-central1-mining-app-46db1.cloudfunctions.net/mining/v1/login",
      `${baseUrl}login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Register(body) {
  let response;
  try {
    response = await fetch(
      // "https://us-central1-mining-app-46db1.cloudfunctions.net/mining/v1/admin",
      `${baseUrl}admin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function SignatureLogin(signature) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/signatureLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        signature: signature,
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAdmin(token) {
  let response;
  try {
    response = await fetch(
      // "https://us-central1-mining-app-46db1.cloudfunctions.net/mining/v1/admin",
      `${baseUrl}admin`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetUser(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}users/filter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COLLECTION --------------------
export async function CreateCollection(body) {
  let response;
  try {
    response = await fetch("/v1/createCollection", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateNFT(body) {
  let response;
  try {
    response = await fetch("/v1/createAsset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function createSub(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}createsubcategory  `, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ImportCollection(address) {
  let response;
  try {
    response = await fetch("/v1/assets/import/" + address, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateCollection(id, body) {
  let response;
  try {
    response = await fetch("/v1/collection/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetCollections(query) {
  let response;
  try {
    response = await fetch("/v1/assets/collection/filter" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCollection(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyCollection(id, token) {
  let response;
  try {
    response = await fetch("/v1/collection/verify/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COMMENT --------------------
export async function GetComments(id) {
  let response;
  try {
    response = await fetch("/v1/comments/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- Reports --------------------
export async function GetReports(params, body) {
  let response;
  try {
    response = await fetch("/v1/reports/" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetReport(id) {
  let response;
  try {
    response = await fetch("/v1/report/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COMMENT --------------------
export async function GetCategories({}, projectId, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}adminCategories?projectId=${projectId}`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetIdoToken(id, params, body) {
  let response;
  try {
    response = await fetch(`/emp/getAssetssAdmin?categoryId=${id}` + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetFinancialAssets(id, query) {
  let response;
  try {
    response = await fetch(`/emp/getAssetss?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetInitialNFTofferings(id, query) {
  let response;
  try {
    response = await fetch(`/emp/getAssetssAdmin?categoryId=${id}` + query, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetManagedFunds(id) {
  let response;
  try {
    response = await fetch(`/emp/getAssetss?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetIdoTokenById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}investor/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCategoryByid(id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}category/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getArtistById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}artist/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetInoTokenById(id) {
  let response;
  try {
    response = await fetch(`/emp/GetassetById/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostAssets(body, token) {
  try {
    const response = await fetch(
      // `https://us-central1-mining-app-46db1.cloudfunctions.net/mining/v1/product`,
      `${baseUrl}product`,
      {
        method: "POST",
        // body:data,
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400) {
      var errorResponse = await response.json();

      throw new Error(errorResponse.error);
    } else if (response.status === 500) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
    {
      return response;
    }
  } catch (e) {
    throw e;
  }
}
export async function PostSellers(body, token) {
  try {
    const response = await fetch(
      // `https://us-central1-mining-app-46db1.cloudfunctions.net/mining/v1/admin`,
      `${baseUrl}admin`,
      {
        method: "POST",
        // body:data,
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400) {
      var errorResponse = await response.json();

      throw new Error(errorResponse.error);
    } else if (response.status === 500) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
    {
      return response;
    }
  } catch (e) {
    throw e;
  }
}
export async function createcategory(body, token) {
  try {
    const response = await fetch(`${baseUrl}category`, {
      method: "POST",
      // body:data,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    } else if (response.status === 500) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
    {
      return response;
    }
  } catch (e) {
    throw e;
  }
}

export async function GetSubcategoriesById(id) {
  let response;
  try {
    response = await fetch(`/v1/GetSubcategories?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Idodelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// export async function Idodelete(id) {
//   let response;
//   try {
//     response = await fetch(`${baseUrl}asset/${id}`, {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//       },
//     });
//   } catch (e) {
//     throw new Error(e.message);
//   }

//   if (response.status === 200) {
//     return await response.json();
//   } else {
//     let message = await response.json();
//     throw new Error(message.error);
//   }
// }

export async function adminDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function idoUpdate(body, _id) {
  let response;
  try {
    response = await fetch(`/emp/asset/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function propertyRankUpdate(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/updateUser/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function productUpdate(id, body, projectId, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}product/${id}?projectId=${projectId}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function profileUpdate(id, body, projectid) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/admin/update/${id}?projectId=${projectid}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function inoUpdate(body, id) {
  let response;
  try {
    response = await fetch(`/emp/asset/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetActive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/activate/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetDeactive(_id) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/deactivate/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetempAssetsByFilter(body) {
  let response;
  try {
    response = await fetch(
      "https://realestate.smartchain.in:3200/v1/assets/filters",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetLogo() {
  let response;
  try {
    response = await fetch(
      `https://securrencyuat.zagtrader.com/API/TickerLogo.php?tickerId=36855`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetZagdetail(id) {
  let response;
  try {
    response = await fetch("/emp/zagassetById/" + id, {
      // mode: 'no-cors',
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEmpadmin(body) {
  let response;
  try {
    response = await fetch("/v1/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEmpadminById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetdasActive(id) {
  let response;
  try {
    response = await fetch(`/emp/zagasset/activate/${id}`, {
      //emp/asset/dasactivate
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function assetdasactive(data) {
  let response;
  try {
    response = await fetch(`/emp/zagasset/activate`, {
      ///emp/asset/dasdeactivate
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ data: data }),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetdasDeactive(data) {
  let response;
  try {
    response = await fetch(`/emp/zagasset/deactivate`, {
      ///emp/asset/dasdeactivate
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ data: data }),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetZaglink() {
  let response;
  try {
    response = await fetch(
      "/API/Backend/GetCompanyValuationData.php?token=6fc1aa548194c8c67d535b2bd70844b7293420bc9c052402f3061e3a21a61bb4&market_symbol=ADX&ticker_symbol=ADPORTS",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetZagmarket(body) {
  let response;
  try {
    response = await fetch("/emp/filter/Assets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetCollectionr(id, body) {
  let response;
  try {
    response = await fetch("/emp/filter/newzagAssets?exchangeId=" + id, {
      // /emp/filter/zagAssets?exchangeId=  /emp/filter/zagAssets?
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostAssetsfilterbydate(body) {
  let response;
  try {
    response = await fetch("/emp/assets/period", {
      // /emp/filter/zagAssets?exchangeId=  /emp/filter/zagAssets?
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetActivefund(id) {
  let response;
  try {
    response = await fetch(`/emp/singleasset/activate/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetDeactivefund(id) {
  let response;
  try {
    response = await fetch(`/emp/singleasset/deactivate/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function Collectionassetdelete(id) {
  let response;
  try {
    response = await fetch(`/v1/collection/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function collectionassetUpdate(body, id) {
  let response;
  try {
    response = await fetch(`/v1/assets/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function Collassetdelete(id) {
  let response;
  try {
    response = await fetch(`/v1/asset/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetliveCollections() {
  let response;
  try {
    response = await fetch("/v1/collections/top", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function zagUpdate(body, id) {
  let response;
  try {
    response = await fetch(`http://54.145.51.238:3200/emp/zagnewAssets/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetOwnedbywallet(walletaddr) {
  let response;
  try {
    response = await fetch(`/v1/user/assets/${walletaddr}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(walletaddr),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetInterestedProperties() {
  let response;
  try {
    response = await fetch(`${baseUrl}getinterest`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCount() {
  let response;
  try {
    response = await fetch(`${baseUrl}getCount`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getInterestedByFilter(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}interest/filter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvestorsByFilter(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/user?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvestors() {
  let response;
  try {
    response = await fetch(`${baseUrl}investors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getOrders(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}orders?projectId=${projectId}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getArtistByFilter(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}getArtist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getTransactionFee(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}get/all/investment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUsersDayWise() {
  let response;
  try {
    response = await fetch(`${baseUrl}users/daywise`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getMonthwiseArtist() {
  let response;
  try {
    response = await fetch(`${baseUrl}users/monthwiseArtist`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCounts(projectId) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}admin/user/count?projectId=${projectId}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getCataloguesdetails(body, projectid, token) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(`${baseUrl}getAllProducts?projectId=${projectid}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getMineralsdetails(body, projectid, token) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(
      `${baseUrl}product/filterNew?projectId=${projectid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getPlatformdetails(body, projectid, token) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(`${baseUrl}allPlatform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getSellerOrderdetails(body, projectid, token) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(`${baseUrl}seller/order?projectId=${projectid}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getTransactionDetails(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}getTransactions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUsersMonthWise() {
  let response;
  try {
    response = await fetch(`${baseUrl}users/monthwise`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getGenderCount() {
  let response;
  try {
    response = await fetch(`${baseUrl}getGenderCount`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getCatalogueByid(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}product/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvestmentAmount(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}transactions/asset/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvestmentById(id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}investment/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getOrderById(id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}order/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getPlatformById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform/byId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function updatePlatformServicesById(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform/byId/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function RemovePlatformServicesField(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform/removeField/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getDetailById(id, page, pages) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}admins/asset/${id}?page=${page ? page : pages}`,
      {
        method: "GET",
        headers: {
          "content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function SellerEmailStatus(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}/seller/email`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvestmentsByassetId(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}get/investment/asset/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getUserBalance(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}balance/user/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getCatalogueByuserId(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}asset/user/${id}?page=${page}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getInvestorBycatalogue(id, page) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}admin/transactions/user/${id}?page=${page}`,
      {
        method: "GET",
        headers: {
          "content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getChartValue(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/chart/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getTotalInvestmentValue(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}transactions/amount/total/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getChartCountByuser(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}investors/chart/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getPortFolio(id, page) {
  let response;
  try {
    response = await fetch(`${baseUrl}invset/admin/user/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getPercentage() {
  let response;
  try {
    response = await fetch(`${baseUrl}getpercentage`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateproductinCategory(body, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}multipleproduct`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getNotification() {
  let response;
  try {
    response = await fetch(
      `${baseUrl}/configuration/6426a03c1c6666c9ea1ff0d9`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateNotification(body) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}configuration/update/6426a03c1c6666c9ea1ff0d9`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updatecategory(id, body, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}category/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getTopInvestment() {
  let response;
  try {
    response = await fetch(`${baseUrl}transactions/investment`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function productdelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}product/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function productdelete1(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}product/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function categoryDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}category/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getAllProducts(page, projectId) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}products?page=${page ? page : 1}&projectId=${projectId}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function orderUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}order/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function PlatformServiceUpdate(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getPayment(body, projectId, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}payment?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getForumRequests(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}forumRequests?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getForums(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}forums?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function requestUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}forum/request/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateUser(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}admin/update/${id}?`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function CreateNotification(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}notification/create`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getUserById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getUserdocumentsById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}getDocumentByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getUserdocumentscompanyById(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}getCompanyKYCByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getSignedNCNDAById(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}getSignedNCNDAById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getSignedPlatformAgreeById(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}getSignedPlatformAgreementById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getOffers(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}offers?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getSellerOffers(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}seller/offer?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getSellers(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}getSellerByFilter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function OfferUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}offer/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function feedbackUpdate(body, id) {
  let response;
  try {
    response = await fetch(`${baseUrl}feedback/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getOffersByid(id, projectId) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}offer/product/${id}?projectId=${projectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        // body: JSON.stringify(body)
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getNotificationByUserId(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}notification/user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function deleteNotificationByUserId(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}notification/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function editNotificationById(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}notification/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getNotificationByFilter(type) {
  let response;
  try {
    response = await fetch(`${baseUrl}notification?type=rent,invest`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function passwordUpdate(body, id, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}updatePassword`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "x-access-token": token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateProject(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}project/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ProjectByid(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}project/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ForumDelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}forum/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function getFeedbacks(body, projectId) {
  let response;
  try {
    response = await fetch(`${baseUrl}feedback/filter?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function updateMultipleCategries(body, token) {
  let response;
  try {
    response = await fetch(`${baseUrl}multipleCategories`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function Sellerdelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}deleteSellerById/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function ordermailStatus(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}order/status/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function CreateReport(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateReport(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}report/approve/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetProduct(projectId, productId) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}report?projectId=${projectId}&productId=${productId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        // body: JSON.stringify(body)
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function documentdelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}report/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function documentgetbyId(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}report/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function documenteditbyId(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}report/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function userDoceditbyId(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}editDocumentById/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function usercompanyDoceditbyId(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}editCompanyKYCById/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function CreateNcnda(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}createNCNDA`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateSignedPlatformAgreement(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}createSignedPlatformAgreement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function CreateSignedNCNDA(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}createSignedNCNDA`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetNcnda(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}getNCNDAByUserId/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Ncndadelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}deleteNCNDAById/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function CreatePlatformAgreement(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}createPlatformAgreement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetPlatformAgreement(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}getPlatformAgreementByUserId/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PlatformAgreementdelete(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}deletePlatformAgreementById/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PlatformAgreementSigneddelete(id) {
  let response;
  try {
    response = await fetch(
      `${baseUrl}deleteSignedPlatformAgreementById/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function deleteKycDocument(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}deleteDocumentById/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log("get filter exception", e);
  }
}
export async function deleteKyccompanyDocument(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}deleteCompanyKYCById/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log("get filter exception", e);
  }
}
export async function createKycCompany(data) {
  let response;
  try {
    response = await fetch(`${baseUrl}createCompanyKYC`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log("get filter exception", e);
  }
}
export async function createKycOrder(data) {
  let response;
  try {
    response = await fetch(`${baseUrl}createDocument`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log("get filter exception", e);
  }
}
export async function editKycindividual(id, data) {
  let response;
  try {
    response = await fetch(`${baseUrl}editDocumentById/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log("get filter exception", e);
  }
}
export async function editKyccompany(id, data) {
  let response;
  try {
    response = await fetch(`${baseUrl}editCompanyKYCById/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return response.data;
    } else {
      const message = response.data;
      throw new Error(message.error);
    }
  } catch (e) {
    console.log("get filter exception", e);
  }
}
export async function getTransactionInvest(body, projectid, token) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(
      `${baseUrl}transaction/filter?projectId=${projectid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getAdminRental(body, projectid, token) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(`${baseUrl}rental/admin?projectId=${projectid}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getInvest(body, projectid) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(
      `${baseUrl}investment/admin?projectId=${projectid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function ncndaDoceditbyId(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}editSignedNCNDAById/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function signedplatformAgreementDoceditbyId(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}editSignedPlatformAgreementById/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ncndaDocdeletebyId(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}deleteSignedNCNDAById/${id}`, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function platformDoceditbyId(id, body) {
  let response;
  try {
    response = await fetch(`${baseUrl}editSignedPlatformAgreementById/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function AdminForgotPassword(body) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(`${baseUrl}adminresetPassword/code`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function AdminResetPassword(code, body) {
  let response;

  try {
    // response = await fetch(`${baseUrl}product/filter?projectId=${projectid}`, {
    response = await fetch(`${baseUrl}resetPassword?code=${code}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdatePlatformService(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreatePlatformService(body) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetPlatformService(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function DeletePlatformService(id) {
  let response;
  try {
    response = await fetch(`${baseUrl}platform/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
