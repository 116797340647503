import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GetUsersMonthWise } from "../../../../services/ApiServices";

export function LineChart() {
  const [count, setCount] = useState([]);
  const [month, setMonth] = useState([]);
  const [investorCount, setInvestorCount] = useState([]);
  const [admin, setAdmin] = useState([]);

  const getCount = async () => {
    let result;

    result = await GetUsersMonthWise();
    try {
      if (result.success) {
        setCount(result?.userData);
        setInvestorCount(result?.investorData);

        setMonth(result.labelData);
        setAdmin(result.adminsData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCount();
  }, []);

  const data = {
    series: [
      {
        name: "user",
        data: [1, 10, 30, 50],
      },

      // {
      //   name: "Investors",
      //   data: [10, 20, 20, 30],
      // },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },

      colors: ["#FDD835", "#e2073b", "#8F00FF"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          color: ["#FDD835", "#e2073b", "#8F00FF"],
          gradientToColors: ["#48b1bf", "#e2073b", "#8F00FF"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: month,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        height={500}
      />
    </div>
  );
}
