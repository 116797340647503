import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { message } from "antd";
import { AdminForgotPassword } from "../../../../services/ApiServices";
import { Mobile } from "../../../../hooks/mobile";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const isSmallScreen = Mobile();
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // requestPassword(values.email)
      //   .then(() => setIsRequested(true))
      //   .catch(() => {
      //     setIsRequested(false);
      // setSubmitting(false);
      //     setStatus(
      //       intl.formatMessage(
      //         { id: "AUTH.VALIDATION.NOT_FOUND" },
      //         { name: values.email }
      //       )
      //     );
      //   });
      forgotPass(values.email);
    },
  });
  const [email, setEmail] = useState();
  const [emailerr, setEmailerr] = useState();
  const [isValid, setIsValid] = useState(true);
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValid(validateEmail(e.target.value));
  };

  const forgotPass = async () => {
    let result, body;
    body = {
      email: email,
      subject: "Smart Mining - Password Reset"
    }
    result = await AdminForgotPassword(body);
    try {
      if (result.success) {
        setEmail();
        setEmailerr();
        message.success(result.message)
      } else {
        console.log("Error");
        message.error(result.message)
      }
    } catch (e) {
      console.log(e);
      // setIsRequested(false);
      message.error(e.message)
    }
  }
 

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block", border: "1px solid $light-grey",
        borderRadius: "20px",
        padding: "40px 40px 20px 40px",
        background: "white",
        boxShadow: "0px 0px 7px -1px rgba(0,0,0,0.35)",
        width: isSmallScreen ? "100%" : "50%" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          {/* <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          > */}
          {/* {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )} */}
          {emailerr && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {emailerr}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              style={{ borderColor: "rgb(36 173 219)" }}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              // {...formik.getFieldProps("email")}
              placeholder="email"
              value={email}
              // onChange={(e) => setEmail(e.target.value)}
              onChange={handleEmailChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              // id="kt_login_forgot_submit"
              // type="submit"
              style={ { backgroundColor: "#010440", color: "#f0d03d" }}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              // disabled={formik.isSubmitting}
              onClick={() => {
                if (email && isValid) {
                  forgotPass()
                } else if (!isValid) {
                  setEmailerr('Please enter a valid email address')
                } else {
                  setEmailerr('Please a enter a email')
                }
              }}
            >
              Submit
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                style={{ color: "010440", border: "none" }}
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
          {/* </form> */}
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
